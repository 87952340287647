import React from 'react'
import { convertFromRaw, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BlockLink } from '../components/decorators/link/BlockLink';
import {
    CUSTOM_STYLE_MAP
} from "../helpers/styles";
import { DEFAULT_CLASSES, DEFAULT_STYLES } from "../helpers/blockTypes";
import BlockImage from '../components/blocks/image/BlockImage';
import BlockVideoYoutube from '../components/blocks/videoYoutube/BlockVideoYoutube';
import BlockButton from "../components/blocks/button/BlockButton";
import BlockCode from "../components/blocks/code/BlockCode";
import { generateSlug } from "../../../helpers/common";
import { DRAFT_FONT_BACKGROUND_COLORS, DRAFT_FONT_COLORS } from "../../../settings/content/draft";

export const getPlainTextFromContent = contentState => {
    if (!contentState?.blocks?.some(b => !!b.text)) return '';
    // let editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(contentState))));
    let editorState = EditorState.createWithContent(convertFromRaw((contentState)));
    return editorState.getCurrentContent().getPlainText();
}

function generateStyles() {

    let inlineStyles = {};

    Object.keys(CUSTOM_STYLE_MAP).map(style => {
        inlineStyles[style] = {
            ...inlineStyles[style],
            ...{
                ...(!!inlineStyles[style] && !!inlineStyles[style]['style'] ? inlineStyles[style]['style'] : {}),
                ...CUSTOM_STYLE_MAP[style]
            }
        }
    });

    Object.keys(DRAFT_FONT_COLORS).map((fontColor, index) => {
        inlineStyles[`FONT_COLOR_${index + 1}`] = {color: `var(--font-color-${index + 1})`}
    });

    Object.keys(DRAFT_FONT_BACKGROUND_COLORS).map((backgroundColor, index) => {
        inlineStyles[`FONT_BACKGROUND_COLOR_${index + 1}`] = {backgroundColor: `var(--font-background-color-${index + 1})`}
    });

    return inlineStyles;
}

function getFullClass(block, passedClasses) {

    let className = '';
    let align = block.data.align;
    let type = block.type;
    // get default content editor classes
    if (Object.keys(DEFAULT_CLASSES).includes(type)) className = DEFAULT_CLASSES[type];
    // add custom classes if necessary
    if (!!passedClasses && Object.keys(passedClasses).includes(type)) className = !className ? passedClasses[type] : className + ' ' + passedClasses[type];
    //
    if (!!DEFAULT_STYLES[align]) className += ' ' + DEFAULT_STYLES[align];

    return className
}

function getHeading(block, className) {
    const tagsVocabulary = ['empty', 'one', 'two', 'three', 'four', 'five', 'six']; // empty = tired of this [index + 1] thing
    const headingNumber = tagsVocabulary.findIndex(h => block.type.includes(h));
    return {
        element: React.createElement(
            `h${headingNumber}`,
            {
                className: className,
                ...(headingNumber !== 1 ? {
                    id: generateSlug(block.text),
                    ['data-text-content']: block.text
                } : {}),
            }
        ),
    };
}

export const convertEditorStateToHTML = (contentState, {
    styles: styles = {}
}) => {

    // let editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(JSON.stringify(contentState))));
    let options = {};
    const editorState = EditorState.createWithContent(convertFromRaw(contentState));
    const generatedStyles = generateStyles();

    options = {
        blockToHTML: (block) => {

            const className = getFullClass(block, styles);
            // br on enter press
            if (!block.text && (block.type !== 'code-block')) return <div className={className}><br/></div>;

            switch (block.type) {
                case 'atomic':
                    return null; // prevents atomic blocks to create extra elements on start and end
                case 'header-one':
                case 'header-two':
                case 'header-three':
                case 'header-four':
                case 'header-five':
                case 'header-six':
                    return getHeading(block, className);
                case 'unstyled':
                case 'paragraph':
                    return <p className={className}/>;
                case 'unordered-list-item':
                    return {
                        element: React.createElement('li', {className: className}),
                        nest: React.createElement('ul', {}),
                    };
                case 'ordered-list-item':
                    return {
                        element: React.createElement('li', {className: className}),
                        nest: React.createElement('ol', {}),
                    };
                case 'code-block':

                    let textWithoutNewlines = block.text.replace(/(\r\n|\n|\r)/gm, "");


                    if (!textWithoutNewlines) {

                        let newLinesCount = block.text ? (block.text.match(/^[ \t]*$/gm) || []).length : 0;
                        let brs = [];

                        for (let i = 1; i <= newLinesCount; i++) brs.push(<br/>);

                        return (
                            <div className={'draft-html-pre-empty'}>
                                <React.Fragment>
                                    {!!brs.length ? brs.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>) : <br/>}
                                </React.Fragment>
                            </div>
                        )
                    }

                    return {
                        element: React.createElement('code', {className: className}),
                        nest: React.createElement('pre', {className: 'draft-html-pre'}),
                    };
                default:
                    return <p className={className}/>
            }

        },
        styleToHTML: (style) => {

            const currentStyle = !!generatedStyles[style] ? generatedStyles[style] : {};

            switch (style) {
                case 'BOLD' :
                    return <strong style={currentStyle}/>
                case 'ITALIC' :
                    return <em style={currentStyle}/>
                case 'UNDERLINE' :
                    return <u style={currentStyle}/>
                case 'CODE' :
                    return <code style={currentStyle}/>
                default :
                    return <span style={currentStyle}/>
            }
        },
        entityToHTML: (entity, originalText) => {
            switch (entity.type) {
                case 'LINK':
                    return BlockLink({data: entity.data, children: originalText})
                case'img':
                    return BlockImage({imageData: entity.data, converted: true})
                case 'video':
                    return BlockVideoYoutube({videoData: entity.data, converted: true})
                case 'button':
                    return BlockButton({data: entity.data, converted: true})
                case 'code':
                    return BlockCode({data: entity.data, converted: true})
                default:
                    return originalText
            }
        }
    }

    return convertToHTML(options)(editorState.getCurrentContent())
}