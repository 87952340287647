import React, { useEffect, useState } from 'react';
import { ControlButton } from '../_constructor';
import { RichUtils } from 'draft-js';
import Button from "../../../../ripple/Button";

import { DRAFT_FONT_BACKGROUND_COLORS, DRAFT_FONT_COLORS } from "../../../../../settings/content/draft";

export default function FontColors({context, stylesMap, isAllowed}) {

    const [currentPanel, setCurrentPanel] = useState(null);
    const [colors, setColors] = useState({
        fontColorsLight: [],
        fontColorsDark: [],
        fontBackgroundColorsLight: [],
        fontBackgroundColorsDark: [],
    });

    const {editorState, onChange} = context;

    useEffect(() => {

        const fontColorSource = DRAFT_FONT_COLORS;
        const fontBackgroundColorSource = DRAFT_FONT_BACKGROUND_COLORS;
        const fontColorsLight = [], fontColorsDark = [], fontBackgroundColorsLight = [], fontBackgroundColorsDark = [];
        let defineColors = (source, destinationLight, destinationDark) => {
            source.map(color => {
                destinationLight.push(color[0])
                destinationDark.push(color[1] ? color[1] : color[0])
            })
        }

        defineColors(fontColorSource, fontColorsLight, fontColorsDark);
        defineColors(fontBackgroundColorSource, fontBackgroundColorsLight, fontBackgroundColorsDark);

        setColors({
            fontColorsLight: fontColorsLight,
            fontColorsDark: fontColorsDark,
            fontBackgroundColorsLight: fontBackgroundColorsLight,
            fontBackgroundColorsDark: fontBackgroundColorsDark,
        })

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    const handleClickOutside = e => {
        let notPanel = !e.target.closest('.controls-color-panel');
        let notTrigger = !e.target.closest('.controls-color-panel-button-container');
        if (notPanel && notTrigger) setCurrentPanel(null);
    }

    useEffect(() => {
        if (!!currentPanel) window.addEventListener('mousedown', handleClickOutside);
        if (!currentPanel) window.removeEventListener('mousedown', handleClickOutside);
    }, [currentPanel]);

    const inlineStyle = editorState.getCurrentInlineStyle();
    const stylesKeys = Object.keys(stylesMap)
                             .filter(key => key.includes('FONT_COLOR_') || key.includes('FONT_BACKGROUND_COLOR_'));
    const existingColor = stylesKeys.find(style => inlineStyle.has(style) && style.includes('FONT_COLOR_'));
    const existingBack = stylesKeys.find(style => inlineStyle.has(style) && style.includes('FONT_BACKGROUND_COLOR_'));
    const color = !!existingColor ? stylesMap[existingColor].color : null;
    const backColor = !!existingBack ? stylesMap[existingBack].backgroundColor : null;

    const setColor = colorIndex => {

        let newState = editorState;

        if (!!existingColor && currentPanel === 1 || !!existingBack && currentPanel === 2) {
            newState = RichUtils.toggleInlineStyle(
                newState,
                currentPanel === 1 ? existingColor : existingBack
            );
        }

        newState = RichUtils.toggleInlineStyle(
            newState,
            `${currentPanel === 1 ? 'FONT_COLOR_' : 'FONT_BACKGROUND_COLOR_'}${colorIndex}`,
        );

        onChange(newState);

    }

    const clearColor = () => {
        let newState = editorState;
        if (!!existingColor && currentPanel === 1 || existingBack && currentPanel === 2) {
            newState = RichUtils.toggleInlineStyle(newState, currentPanel === 1 ? existingColor : existingBack);
        }
        onChange(newState);
    }

    const renderPanel = (panel, currentBackColor, currentColor) => {

        const showPanel = currentPanel === panel ? ' controls-color-panel--show' : '';
        const light = panel === 1 ? colors.fontColorsLight : colors.fontBackgroundColorsLight;
        const dark = panel === 1 ? colors.fontColorsDark : colors.fontBackgroundColorsDark;
        const defineSelected = colorIndex => {
            let current = panel === 1 ? currentColor : currentBackColor;
            if (!current) return false;
            let currentName = panel === 1 ? 'var(--font-color-' : 'var(--font-background-color-';
            let currentIndex = current.split(currentName)[1].split(')')[0];
            if (isNaN(parseFloat(currentIndex))) return false;
            return colorIndex + 1 === parseFloat(currentIndex);
        }

        return (
            <div className={`controls-color-panel${showPanel}`}>
                <div className='controls-color-panel__themes'>
                    <div className='controls-color-panel__themes__theme'>
                        {light.map((color, index) => {
                            const selected = defineSelected(index);
                            return (
                                <div className='controls-color-panel__themes__theme__color' key={index}>
                                    <div
                                        className='controls-color-panel__themes__theme__color__item'
                                        style={{backgroundColor: color}}
                                        onClick={() => setColor(index + 1)}
                                    />
                                    {!!selected &&
                                        <i className='fas fa-check controls-color-panel__themes__theme__color__check'/>}
                                </div>
                            )
                        })}
                    </div>
                    <div className='controls-color-panel__themes__theme'>
                        {dark.map((color, index) => {
                            const selected = defineSelected(index);
                            return (
                                <div className='controls-color-panel__themes__theme__color' key={index}>
                                    <div
                                        className='controls-color-panel__themes__theme__color__item'
                                        style={{backgroundColor: color}}
                                        onClick={() => setColor(index + 1)}
                                    />
                                    {!!selected &&
                                        <i className='fas fa-check controls-color-panel__themes__theme__color__check'/>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='controls-color-panel__bottom'>
                    <Button
                        title='Clear color'
                        iconRight='far fa-trash-alt'
                        className='controls-color-panel__bottom__button'
                        onClick={clearColor}
                        visuals={{height: '2.8rem'}}
                    />
                </div>
            </div>
        )
    }


    return (
        <>
            {
                isAllowed('colorFont') &&
                <div style={{position: 'relative'}}>
                    <ControlButton
                        active={currentPanel === 1}
                        icon='fas fa-paint-brush'
                        onClick={() => setCurrentPanel(currentPanel === 1 ? null : 1)}
                    />
                    {renderPanel(1, backColor, color)}
                </div>
            }
            {
                isAllowed('colorBackgroundFont') &&
                <div style={{position: 'relative'}}>
                    <ControlButton
                        active={currentPanel === 2}
                        icon='fas fa-square'
                        onClick={() => setCurrentPanel(currentPanel === 2 ? null : 2)}
                    />
                    {renderPanel(2, backColor, color)}
                </div>
            }
        </>
    );
}