import React, { useState } from 'react'
import { dispatchNotification } from '../../../../../layouts/app/components/notificationSystem/notifications';
import Button from "../../../../ripple/Button";
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import { CardItem } from "../../../../card/Card";
import { insertBlock } from "../_insert";
import DraftEditor from "../../../DraftEditor";
import Messages from "../../../../Messages";
import { generateRandomID } from "../../../../../helpers/common";
import { DRAFT_CODE_LANGUAGES } from "../../../../../settings/content/draft";
import BlockCode from "./BlockCode";
import Switch from "../../../../Switch";
import isEqual from 'lodash.isequal'

const CONTENT_DEFAULT = {
    "blocks": [
        {
            "key": "dhs5c",
            "text": "",
            "type": "code-block",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
}

export default function DialogueCode({entityKey, context, ...props}) {

    const [data, setData] = useState({
        content: props.data?.content || CONTENT_DEFAULT,
        id: props.data?.id || generateRandomID(),
        language: props.data?.language || undefined,
        hideCopy: props.data?.hideCopy || false,
        hideLanguage: props.data?.hideLanguage || false,
    });

    useEffect(() => {
        setTimeout(() => document.getElementById('draft-code-language-current')?.scrollIntoView(), 50);
    }, []);

    let messages = [];

    if (!data.content.blocks.some(b => b.type === 'code-block')) {
        messages.push({
            message: 'No code-block is found. Code-block is required!',
            type: 'error',
            container: 'draft-code-dialogue',
            block: 'draft-code-dialogue',
            target: 'draft-code-dialogue-editor',
        })
    }

    if (data.content.blocks.some(b => b.type !== 'code-block')) {
        messages.push({
            message: 'Only one code-block is allowed!',
            type: 'error',
            container: 'draft-code-dialogue',
            block: 'draft-code-dialogue',
            target: 'draft-code-dialogue-editor',
        })
    }

    if (data.content.blocks.filter(b => b.type === 'code-block')?.length > 1) {
        messages.push({
            message: 'Only one code-block is allowed!',
            type: 'error',
            container: 'draft-code-dialogue',
            block: 'draft-code-dialogue',
            target: 'draft-code-dialogue-editor',
        })
    }

    const prepareData = e => {
        e.preventDefault();

        if (messages.some(m => m.type === 'error')) {
            dispatchNotification({
                message: 'Please check if data is valid first',
                type: 'error',
            });
        } else {
            insertBlock(data, 'code', context, entityKey);
            store.dispatch(removeLastModalLayer());
        }
    }

    return (
        <>
            <div className={`draft-code-layout`}>
                <CardItem
                    className='draft-code-layout__languages'
                >
                    <p className='draft-code-subtitle' style={{padding: '0 12px'}}>Select language</p>
                    <div className={`draft-code-select-buttons`} style={{paddingTop: '10px'}}>
                        <Button
                            id={data.language === undefined ? 'draft-code-language-current' : undefined}
                            className={data.language === undefined ? 'draft-code-select-buttons__selected' : ''}
                            title={'None'}
                            iconLeft={'fas fa-times'}
                            onClick={() => setData({...data, ...{language: undefined}})}
                            displayType='text'
                        />
                        {
                            DRAFT_CODE_LANGUAGES.map((language, index) => (
                                <Button
                                    id={data.language === language.code ? 'draft-code-language-current' : undefined}
                                    className={data.language === language.code ? 'draft-code-select-buttons__selected' : ''}
                                    title={language.name || language.code}
                                    iconLeft={language.icon || 'fas fa-code'}
                                    onClick={() => setData({...data, ...{language: language.code}})}
                                    displayType='text'
                                    key={index}
                                />
                            ))
                        }
                    </div>
                </CardItem>
                <CardItem
                    id={`draft-code-dialogue`}
                    className='draft-code-layout__editor'
                    title='Preview content'
                    messages={messages}
                    showMessages
                >
                    <p className='draft-code-subtitle'>Edit code</p>
                    <DraftEditor
                        className={`draft-code-editor`}
                        content={data.content ? data.content : null}
                        controls={['styling', 'alignment', 'link']}
                        styles={{
                            'unstyled': 'blog-article-preview__unstyled',
                            'paragraph': 'blog-article-preview__paragraph',
                        }}
                        onChange={content => setData({...data, ...{content}})}
                        isCodeEditor
                    />
                    <Messages messages={messages} target={`draft-code-dialogue-editor`}/>
                </CardItem>
                <div className='draft-code-layout__preview'>
                    <CardItem
                        className='draft-code-layout__preview__settings'
                        title='Preview content'
                        showMessages
                    >
                        <p className='draft-code-subtitle'>Edit settings</p>
                        <Switch
                            className='draft-code-switch'
                            title='Hide copy button'
                            value={data.hideCopy}
                            onChange={bool => setData({...data, ...{hideCopy: bool}})}
                        />
                        <Switch
                            className='draft-code-switch'
                            title='Hide language name'
                            value={data.hideLanguage}
                            onChange={bool => setData({...data, ...{hideLanguage: bool}})}
                        />
                    </CardItem>
                    <CardItem
                        className='draft-code-layout__preview__code'
                        title='Preview content'
                        showMessages
                    >
                        <p className='draft-code-subtitle'>Preview</p>
                        <div className='content-editor draft-code-preview'>
                            <BlockCode
                                context={context}
                                data={data}
                                entityKey={entityKey}
                                converted
                            />
                        </div>
                    </CardItem>
                </div>
            </div>
            <CardItem
                notContained
                className='draft-code-bottom'
            >
                {
                    !isEqual(data, props.data) &&
                    <Messages
                        padding='0 20px 0 0'
                        messages={[{
                            message: 'Changes are not applied. Save changes by pressing "Insert"',
                            type: 'warning'
                        }]}
                    />
                }
                <Button
                    title='Insert'
                    iconRight='fas fa-save'
                    disabled={messages.some(m => m.type === 'error')}
                    onClick={prepareData}
                    visuals={{height: '5rem'}}
                />
            </CardItem>
        </>
    )
}