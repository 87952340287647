import React, { useEffect, useRef, useState } from 'react'
import { CardItem } from "../../../../card/Card";
import Loading from "../../../../Loading";
import showModalDefault from "../../../../../helpers/ui/ModalDefault";
import DialogueImage from "./DialogueImage";
import { removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import { store } from "../../../../../redux/store";
import { createFetch } from "../../../../../helpers/fetch/FetchRequest";
import { dispatchNotification } from "../../../../../layouts/app/components/notificationSystem/notifications";

export default function DialogueImageSource({context}) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [image, setImage] = useState(''); // main prop, that defines if picture was added
    const [attachedLink, setAttachedLink] = useState(''); // can be added further to use pic as link
    const [internal, setInternal] = useState(false); // defines if picture is stored on our servers
    // inputs
    const [linkSource, setLinkSource] = useState('');
    // drag'n'drop handlers
    const [isDragContainer, setIsDragContainer] = useState(false);
    const [isDragTrigger, setIsDragTrigger] = useState(false);
    let dragContainer = useRef();

    const onDragLeave = e => {
        // makes default styles (no action) when leaving browser space
        e.preventDefault();
        // dragleave or dragover (not sure) acts inconsistent randomly switching styles while on target, so this is a fix
        if (!dragContainer.current.contains(e.target)) {
            setIsDragContainer(false);
            setIsDragTrigger(false)
        }
    }

    const onDragOver = e => {
        e.preventDefault();
        // when some drag action started
        if (dragContainer.current.contains(e.target)) {
            setIsDragTrigger(true)
        } else {
            setIsDragTrigger(false);
            setIsDragContainer(true);
        }
    }

    const onDrop = e => {
        // when drag'n'drop is over
        e.preventDefault();
        e.stopPropagation();
        setIsDragContainer(false);
        setIsDragTrigger(false);
    }

    useEffect(() => {
        document.addEventListener('dragleave', onDragLeave);
        document.addEventListener('dragover', onDragOver);
        document.addEventListener('drop', onDrop);
        return () => {
            document.removeEventListener('dragleave', onDragLeave);
            document.removeEventListener('dragover', onDragOver);
            document.removeEventListener('drop', onDrop);
        }
    }, []);

    useEffect(() => {
        if (!!image) {
            store.dispatch(removeLastModalLayer());
            setTimeout(() => {
                showModalDefault({
                    component: (
                        <DialogueImage
                            context={context}
                            imageData={{attachedLink, image, internal}}
                        />
                    ),
                    title: 'Add picture',
                    maxWidth: '1360px',
                });
            }, 1)
        }
    }, [image]);

    const processAsFile = files => {
        setLoading(true);
        // Create an object of formData
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "file",
            files[0],
            files[0].name,
        );

        createFetch({
            method: 'POST',
            URL: '/',
            headers: {},
            body: formData,
            onSuccess: data => {
                setAttachedLink(data.filename);
                setInternal(true);
                setImage(data.filename);
                setLoading(false);
            },
            onError: error => {
                dispatchNotification({
                    message: 'Something went wrong',
                    type: 'error',
                });
            }
        })
    }

    const onChange = e => {

        const {name, value, files} = e.target;

        setError('');
        setImage('');

        switch (name) {
            case 'linkSource':
                setLoading(true);
                setLinkSource(value);
                let newImage = new Image();

                newImage.onload = () => {
                    setAttachedLink(value);
                    setImage(value);
                    setLoading(false);
                };

                newImage.onerror = () => {
                    setImage('');
                    setLoading(false);
                    setAttachedLink('');
                    setError('Unable to load image form provided source');
                };

                newImage.src = value;
                break;
            case 'imageFile':
                processAsFile(files)
                break;
            default:
                return
        }
    };

    const onImageDrop = e => {
        e.preventDefault();
        if (!e.dataTransfer.files?.[0]?.type?.includes('image/')) {
            dispatchNotification({
                message: 'Not an image!',
                type: 'info',
            });
            return;
        }
        processAsFile(e.dataTransfer.files)
    }

    return (
        <div className='draft-img-source'>
            {
                loading &&
                <Loading
                    type='component-cover'
                    icon={{
                        faCode: 'fas fa-chevron-top',
                        animationType: 'top',
                    }}
                    coverOpacity={1}
                />
            }
            {/*<CardItem>*/}
            {/*    <Input*/}
            {/*        name='linkSource'*/}
            {/*        className='draft-img-source__link'*/}
            {/*        placeholder={'Paste image link'}*/}
            {/*        value={linkSource}*/}
            {/*        onChange={onChange}*/}
            {/*        autofocus*/}
            {/*        isValid={!error ? undefined : false}*/}
            {/*        error={!!error ? error : ''}*/}
            {/*        autoComplete='off'*/}
            {/*        iconLeft='fas fa-image'*/}
            {/*        hint="Please use *.try.direct URL or other whitelisted URL. Otherwise the image would be blocked by firewall which leads to invalid UI."*/}
            {/*        hintType='block'*/}
            {/*        showBlockHintOnError={['propError']}*/}
            {/*    />*/}
            {/*</CardItem>*/}
            <CardItem padding={'20px'}>
                <label
                    htmlFor='image-file'
                    id='draft-img-drop'
                    className={`draft-img-drop${isDragContainer ? ' draft-img-drop--drag' : ''}${isDragTrigger ? ' draft-img-drop--drop-over' : ''}`}
                    onDrop={onImageDrop}
                    ref={dragContainer}
                >
                    <div className='draft-img-drop__text'>Or just drop an image</div>
                    <div className='draft-img-drop__icon'>
                        <i className='far fa-arrow-alt-circle-down draft-img-drop__icon__image'/>
                    </div>
                    <input
                        id='image-file'
                        name='imageFile'
                        accept="image/*"
                        onChange={onChange}
                        type="file"
                    />
                </label>
            </CardItem>
        </div>
    )
}